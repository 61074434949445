import React from 'react'

import {
  Divider,
  InlineList,
  InlineListItem,
  SearchResult,
  Text
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import placeholder1Image from '../../assets/placeholder-1.jpg'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SearchResult"
    components={[{ component: SearchResult }]}
  >
    <Section>
      <Playground>
        <>
          <SearchResult
            category="Uutinen"
            component={<LinkMock to="/some-url" />}
            content="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit."
            date={new Date()}
            heading="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
            headingLevel={2}
            imageSrc={placeholder1Image}
          />
          <Divider />
          <SearchResult
            category="Uutinen"
            component={<LinkMock to="/some-url" />}
            content="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit."
            date={new Date()}
            footer={
              <Text
                color="dark"
                size="sm"
              >
                <InlineList>
                  <InlineListItem>Kustomoitu</InlineListItem>
                  <InlineListItem>Footer</InlineListItem>
                </InlineList>
              </Text>
            }
            heading="Lorem ipsum dolor sit amet, consectetuer adipiscing elit"
            headingLevel={2}
            imageSrc={placeholder1Image}
          />
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
